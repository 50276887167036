<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">

      <a-form-model-item :label="this.$t('秒杀库存.时间轴id')" prop="seckillTimeId" >
          <a-select  style="width: 100%" v-model="form.seckillTimeId" placeholder="请选择时间轴" allowClear>
            <a-select-option  v-for="seckillTime in seckillList" @click="setAcTime(seckillTime)" :value="seckillTime.id">
              {{seckillTime.acName}}{{":"}}{{ seckillTime.startTime}}{{"-"}}{{ seckillTime.endTime }}
            </a-select-option>
          </a-select>
      </a-form-model-item>

      <a-form-model-item prop="seckillPrice" >
        <span slot="label" >
          <a-tooltip>
            {{$t('秒杀库存.秒杀价格')}}<template slot="title">{{$t('秒杀库存.秒杀价格')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.seckillPrice"  :placeholder="$t('通用.输入.请输入')+$t('秒杀库存.秒杀价格')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('通用.文本.选择商品')" prop="goodsId">
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
        </a-button>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table
               :loading="goodsLoading"
               :scroll="{ x: '160%' }"
               rowKey="id"
               :size="tableSize"
               :columns="goodsColumns"
               :data-source="form.goodsList"
               :pagination="false"
               :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      </a-table>







      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSeckill, addSeckill, updateSeckill } from '@/api/zb/seckill'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import {listSeckillTime} from "@/api/seckill/seckillTime";
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectForm";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    RadioGoodsSelectForm,
  },
  data () {
    return {
      goodsLoading: false,

      seckillList: [],//时间轴List
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
      ],
      // 表单参数
      form: {
        id: null,
        goodsList: [],

        seckillList: [],//时间轴List
        auditPage: false,
        seckillTimeId: null,

        goodsId: null,

        goodsName: null,

        seckillPrice: null,

        goodsPrice: null,

        seckillNum: null,

        goodsPicture: null,

        stock: null,

        shopId: null,

        shopName: null,

        checkStatus: '0',

        complete: null,

        refuseReason: null,

        startTime: null,

        endTime: null,

        maxBuyCount: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        goodsName: [
          { required: true, message: this.$t('秒杀库存.商品名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        stock: [
          { required: true, message: this.$t('秒杀库存.秒杀商品库存')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        checkStatus: [
          { required: true, message: this.$t('秒杀库存.状态： 1：待审核   2：审核失败  3：审核成功,4:过期未审核  5：活动已结束')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {this.getSecKillTimeListValue();this.getCateList(); this.getBrandList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    setAcTime(value) {
      debugger
      this.form.startTime=value.startTime
      this.form.endTime=value.endTime

    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        goodsList: [],
        id: null,
        seckillList: [],//时间轴List
        seckillTimeId: null,
        goodsId: null,
        goodsName: null,
        seckillPrice: null,
        goodsPrice: null,
        seckillNum: null,
        goodsPicture: null,
        stock: null,
        shopId: null,
        shopName: null,
        checkStatus: '0',
        complete: null,
        refuseReason: null,
        startTime: null,
        endTime: null,
        maxBuyCount: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSeckill({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    getSecKillTimeListValue() {
      var that = this;
      listSeckillTime().then((response) => {
        this.seckillList = response.data;
      })
    },
    indexGoodsModalSelect(records) {
      this.form.goodsId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSeckill(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSeckill(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
